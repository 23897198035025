import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./index@gen.scss";

const GeneratedAutomationIndex = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty Process Automation｜正規販売代理店（株）Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyプロセスオートメーションで、労力を削減し、チームは重要な仕事に専念することができます。製品群を見る"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyプロセスオートメーションで、労力を削減し、チームは重要な仕事に専念することができます。製品群を見る"
        />

        <meta
          property="og:title"
          content="PagerDuty Process Automation｜正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDutyプロセスオートメーションで、労力を削減し、チームは重要な仕事に専念することができます。製品群を見る"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/automation//"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/automation//"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Automation"
        textGreen="PagerDuty Process Automation "
        overview=""
      />

      <div className="itoperationsWrapper">
        <div className="itoperations-container">
          <div className="mt-0 center-1">
            <h3 className="center-title md:mb-[0px] mb-[0px]">
              チケット処理業務を自動化しましょう。
            </h3>
            <p className="center-para mb-[30px]">
              PagerDuty Process
              Automationを使用すると、全てのシステムでビジネスおよびITプロセスを自動化することができます。エンジニアは、操作手順を標準化し、他の既存の自動化を組み込んだ自動ジョブを定義し、そのプロセスをAPIやセルフサービスリクエストとして他の関係者に安全に委譲できるようになります。
            </p>
            <div className="mx-auto">
              <p>PagerDuty Process Automationが提供するもの</p>
              <ul className="ml-8 list-disc">
                <li>
                  一般的なインフラストラクチャーとシステムへのプラグイン統合
                </li>
                <li>自動化されたジョブをシンプルかつグラフィカルに定義</li>
                <li>
                  ロールベース、コンテキストベースのアクセス制御、ジョブ実行のログ記録
                </li>
              </ul>
            </div>
            <p className="text-center mt-[30px]">
              これにより、企業は最も一般的な業務手順を自動化し、頻繁に発生する例外処理も自動化することができます。
            </p>
          </div>
          <div className="text-center mt-[100px] mb-[30px]">
            <h3 className="relative center-title max-w-max">
              製品情報
              <div className="absolute w-[30%] h-[1.5px] bottom-[-10px] mx-auto bg-[#4EB346]" />
            </h3>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[0px] md:mt-[10px]">
            <div className="pr-0 text-part">
              <p className="title">PagerDuty Automation Actions</p>
              <p className="para">
                PagerDutyのレスポンダーとイベントルールを自動化に接続し、本番環境における問題の診断と修復を簡単に行うことができます。このPagerDutyアドオンは、PagerDutyのインシデントのコンテキストで既存の自動化タスクをキュレートし、委譲します。
              </p>
              <button className="mt-[30px] p-3 text-[16px] bg-[#4EB346] rounded-[50px] w-[150px] h-[40px] whitespace-nowrap flex items-center justify-center group">
                <a
                  href="/automation/automation-actions/"
                  className="transition duration-[0.3s] ease-in group-hover:text-black text-white text-[16px] font-[700]"
                >
                  さらに詳しく
                </a>
              </button>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/automation_image1_464fab902f.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673000500451&tr=w-480,q-100,f-webp"
                alt="automation image"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">PagerDuty Runbook Automation</p>
              <p className="para">
                ランブックの手順を自動タスクに変え、関係者に委ねることができる、SaaS型ランブックオートメーションプラットフォームです。セルフサービス型のIT運用を実現し、他のクラウドやSaaSツールと統合する最も簡単な方法です。
              </p>
              <button className="mt-[30px] p-3 text-[16px] bg-[#4EB346] rounded-[50px] w-[150px] h-[40px] whitespace-nowrap flex items-center justify-center group">
                <a
                  href="/automation/runbook/"
                  className="transition duration-[0.3s] ease-in group-hover:text-black text-white text-[16px] font-[700]"
                >
                  さらに詳しく
                </a>
              </button>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/automation_ruleset_visualizer_3_edcecbf542.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673000500432&tr=w-480,q-100,f-webp"
                alt="auto motion iamge 3"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">PagerDuty Process Automationソフトウェア</p>
              <p className="para">
                完全にファイアウォール内にデプロイされた自己管理プラットフォームで、企業規模のプロセスオートメーションを開発します。このサービスは、セキュリティー、パフォーマンス、および他のセルフマネージドインフラストラクチャーとの接続などの統合要件を満たすための柔軟性を提供します。
              </p>
              <button className="mt-[30px] p-3 text-[16px] bg-[#4EB346] rounded-[50px] w-[150px] h-[40px] whitespace-nowrap flex items-center justify-center group">
                <a
                  href="/automation/process-software/"
                  className="transition duration-[0.3s] ease-in group-hover:text-black text-white text-[16px] font-[700]"
                >
                  さらに詳しく
                </a>
              </button>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/automation_jobs_page_2_69609bdcdd.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673000500318&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="text-center mt-[100px] mb-[30px]">
            <h3 className="relative center-title max-w-max px-[25px] md:px-0">
              何の自動化をご希望ですか？
              <div className="absolute w-[30%] h-[1.5px] bottom-[-10px] mx-auto bg-[#4EB346]" />
            </h3>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[0px] md:mt-[10px]">
            <div className="pr-0 text-part">
              <p className="title">インシデントの診断と修復を自動化</p>
              <p className="para">
                PagerDutyのお客様は、インシデントの半分以上の時間を潜在的な問題のトリアージに費やしています。PagerDuty
                Automation
                Actionsを使用して診断の自動化をPagerDutyで作業するレスポンダーに接続することにより、インシデントごとに15分を節約できます。修復のための自動化を接続することで、一般的なインシデントがより迅速に解決されます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Use_automation_to_reduce_resolution_time_toil_1_b848448752.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673000500374&tr=w-480,q-100,f-webp"
                alt="automation image"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">クラウドプラットフォームの運用を自動化</p>
              <p className="para">
                既存のクラウド自動化ツールやSaaSツールを利用して、クラウドプラットフォームの運用プロセスを標準化し、自動化します。このプロセスは、セルフサービス機能として開発者やエンドユーザーに委譲できます。PagerDuty
                Automation
                Actionsを使用すれば、チケット待ちを解消し、労力をエンジニアリングの時間に振り向けることができます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automate_Cloud_Operations_with_Pager_Duty_Runbook_Automation_1_cc4b0bf556.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673000500403&tr=w-480,q-100,f-webp"
                alt="auto motion iamge 3"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">
                セルフマネージメント環境におけるビジネスとITの運用を自動化
              </p>
              <p className="para">
                既存のタスクの自動化を利用して、ITインフラストラクチャーのビジネスとITの運用プロセスを標準化し、自動化します。このプロセスは、セルフサービス機能としてエンドユーザーに委譲できます。運用チームの生産性を向上させ、リクエストが迅速に解決されます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automate_across_disparate_environments_1_2824cc5eb9.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673000500411&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="center-2">
            <h3 className="center-title">インテグレーション</h3>
            <p className="center-para">
              今お使いのツールで動作するため、プロセスを変更する必要はありません。
            </p>
            <p className="text-center mt-[30px]">
              650以上のネイティブな統合機能と、拡張可能なPagerDuty
              APIを使用してワークフローを構築・カスタマイズする機能により、あらゆるツールからデータを統合し、ITインフラへのインサイトを提供します。強化されたEvent
              API
              v2は、全ての受信イベントを共通のフィールドに自動的に正規化します。
            </p>
            <p className="text-center mt-[30px]">
              双方向の拡張機能によりツール切り替えの手間はなく、インラインで問題を修正し、任意のChatOpsツールで運用関連のタスクを自動化可能。現在お使いのツールとよくなじみます。
            </p>
          </div>
          <img
            className="mb-[80px] p-4 md:p-0 md:mt-[30px] md:max-w-[600px]"
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/automation_6f9c0a7096.svg"
            alt="automation"
          />
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedAutomationIndex;
